import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";
const axios = createHttp(appConfig.base2GUrl);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
//文章添加
export function addTrafficArticle(data) {
  return SystemAxios.axios({
    url: `/ins-policy/insNewEnergyMessage/addOrUpdate`,
    method: "POST",
    loading: true,
    data,
  });
}
// 文章列表
export function trafficArticleList(data) {
  return SystemAxios.axios({
    url: `/ins-policy/insNewEnergyMessage/getPagePc`,
    method: "POST",
    loading: true,
    data,
  });
}

// 文章删除
export function trafficArticleDelete(data) {
  return SystemAxios.axios({
    url: `/ins-policy/insNewEnergyMessage/delete`,
    method: "POST",
    loading: true,
    data,
  });
}

// 新能源理赔分页
export function getNewEnergyClaimList(data) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/getPagePc`,
    method: "POST",
    loading: true,
    data,
  });
}

// 理赔案件删除
export function caseNewEnergyDelete(data) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/remove`,
    method: "POST",
    loading: true,
    data,
  });
}

// 理赔案件详情
export function caseNewEnergyDetail(params) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/detailsPc`,
    method: "GET",
    loading: true,
    params,
  });
}

// 案件状态下一步
export function caseNewEnergyStep(params) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/next`,
    method: "GET",
    loading: true,
    params,
  });
}

// 案件编辑
export function editNewEnergyCase(data) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/editor`,
    method: "POST",
    loading: true,
    data,
  });
}

// 理赔附件详情
export function getNewEnergyClaimAttachments(params) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/viewFilePc`,
    method: "GET",
    loading: true,
    params,
  });
}

// 上传附件
export function uploadNewEnergyClaimAttachments(data) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/uploadFilePc`,
    method: "POST",
    loading: true,
    data,
  });
}

// 定损项模糊查询
export function getNewEnergyClaimLossItemByName(params) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/listItemNames`,
    method: "GET",
    loading: true,
    params,
  });
}

// 理赔数据导出
export function exportNewEnergyClaim(data) {
  return SystemAxios.axios({
    url: `/ins-policy/newEnergyClaims/derive`,
    method: "POST",
    responseType: "blob",
    loading: true,
    data,
  });
}
