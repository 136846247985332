<template>
  <div class="main-content">
    <AssociationScroll>
      <AssociationScrollView title="现场照片" name="0">
        <div>
          <div>事故全貌照片</div>
          <BoxUpload
            :fileList="filesObj.SGQMZP"
            attachmentType="SGQMZP"
            :count="0"
            :disabled="!(editObj.SGQMZP.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>受损位置详细情况</div>
          <BoxUpload
            :fileList="filesObj.SSWZXXQK"
            attachmentType="SSWZXXQK"
            :count="0"
            :disabled="!(editObj.SSWZXXQK.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>交通事故认定书</div>
          <BoxUpload
            :fileList="filesObj.JTSGRDS"
            attachmentType="JTSGRDS"
            :count="0"
            :disabled="!(editObj.JTSGRDS.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>三方情况证明-是否涉及三者车辆</div>
          <BoxUpload
            :fileList="filesObj.SFQKZM"
            attachmentType="SFQKZM"
            :count="0"
            :disabled="!(editObj.SFQKZM.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>三方情况证明-是否涉及三者人伤</div>
          <BoxUpload
            :fileList="filesObj.SFSJSZRS"
            attachmentType="SFSJSZRS"
            :count="0"
            :disabled="!(editObj.SFSJSZRS.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>车上人员情况</div>
          <BoxUpload
            :fileList="filesObj.CSRYSFSS"
            attachmentType="CSRYSFSS"
            :count="0"
            :disabled="!(editObj.CSRYSFSS.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>其他补充情况</div>
          <BoxUpload
            :fileList="filesObj.QTBCQK"
            attachmentType="QTBCQK"
            :count="0"
            :disabled="!(editObj.QTBCQK.edit && canEdit)"
          ></BoxUpload>
        </div>
      </AssociationScrollView>
      <AssociationScrollView title="索赔材料" name="1">
        <div>
          <div>赔付意向及权益转让书</div>
          <BoxUpload
            :fileList="filesObj.PFYXJQYZRS"
            attachmentType="PFYXJQYZRS"
            :count="0"
            :disabled="!(editObj.PFYXJQYZRS.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>索赔申请书</div>
          <BoxUpload
            :fileList="filesObj.SPSQS"
            attachmentType="JTSGRDS"
            :count="0"
            :disabled="!(editObj.SPSQS.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>收款银行信息</div>
          <BoxUpload
            :fileList="filesObj.YHK"
            attachmentType="YHK"
            :disabled="!(editObj.YHK.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>其他补充材料上传</div>
          <BoxUpload
            :fileList="filesObj.QTBCCLSS"
            attachmentType="QTBCCLSS"
            :count="0"
            :disabled="!(editObj.QTBCCLSS.edit && canEdit)"
          ></BoxUpload>
        </div>
      </AssociationScrollView>
      <!-- <AssociationScrollView title="赔付/收款" name="2">
        <div>
          <div>收款银行信息</div>
          <BoxUpload
            :fileList="filesObj.YHK"
            attachmentType="YHK"
            :disabled="!(editObj.YHK.edit && canEdit)"
          ></BoxUpload>
        </div>
      </AssociationScrollView> -->
      <AssociationScrollView title="证件照片" name="3">
        <div style="display: flex">
          <div>
            <div>身份证头像面</div>
            <BoxUpload
              :fileList="filesObj.SFZTXM"
              attachmentType="SFZTXM"
              :disabled="!(editObj.SFZTXM.edit && canEdit)"
            ></BoxUpload>
          </div>
          <div style="margin-left: 20px">
            <div>身份证国徽面</div>
            <BoxUpload
              :fileList="filesObj.SFZGHM"
              attachmentType="JTSGRDS"
              :disabled="!(editObj.SFZGHM.edit && canEdit)"
            ></BoxUpload>
          </div>
        </div>

        <div style="display: flex">
          <div>
            <div>营业执照主页</div>
            <BoxUpload
              :fileList="filesObj.YYZZZY"
              attachmentType="YYZZZY"
              :disabled="!(editObj.YYZZZY.edit && canEdit)"
            ></BoxUpload>
          </div>
          <div style="margin-left: 20px">
            <div>营业执照副页</div>
            <BoxUpload
              :fileList="filesObj.YYZZFY"
              attachmentType="YYZZFY"
              :disabled="!(editObj.YYZZFY.edit && canEdit)"
            ></BoxUpload>
          </div>
        </div>
        <div>
          <div>驾驶证</div>
          <BoxUpload
            :fileList="filesObj.JSZ"
            attachmentType="JSZ"
            :count="0"
            :disabled="!(editObj.JSZ.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div style="display: flex">
          <div>
            <div>行驶证主页</div>
            <BoxUpload
              :fileList="filesObj.XSZZY"
              attachmentType="XSZZY"
              :disabled="!(editObj.XSZZY.edit && canEdit)"
            ></BoxUpload>
          </div>
          <div style="margin-left: 20px">
            <div>行驶证副业</div>
            <BoxUpload
              :fileList="filesObj.XSZFY"
              attachmentType="XSZFY"
              :disabled="!(editObj.XSZFY.edit && canEdit)"
            ></BoxUpload>
          </div>
          <div style="margin-left: 20px">
            <div>行驶证年审页</div>
            <BoxUpload
              :fileList="filesObj.XSNSY"
              attachmentType="XSNSY"
              :disabled="!(editObj.XSNSY.edit && canEdit)"
            ></BoxUpload>
          </div>
        </div>
        <div>
          <div>三者驾驶证</div>
          <BoxUpload
            :fileList="filesObj.SZJSZ"
            attachmentType="SZJSZ"
            :count="0"
            :disabled="!(editObj.SZJSZ.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div style="display: flex">
          <div>
            <div>三者行驶证主页</div>
            <BoxUpload
              :fileList="filesObj.SZXSZZY"
              attachmentType="SZXSZZY"
              :disabled="!(editObj.SZXSZZY.edit && canEdit)"
            ></BoxUpload>
          </div>
          <div style="margin-left: 20px">
            <div>三者行驶证副页</div>
            <BoxUpload
              :fileList="filesObj.SZXSZFY"
              attachmentType="SZXSZFY"
              :disabled="!(editObj.SZXSZFY.edit && canEdit)"
            ></BoxUpload>
          </div>
          <div style="margin-left: 20px">
            <div>三者行驶证年审页</div>
            <BoxUpload
              :fileList="filesObj.SZXSZNSY"
              attachmentType="SZXSZNSY"
              :disabled="!(editObj.SZXSZNSY.edit && canEdit)"
            ></BoxUpload>
          </div>
        </div>

        <div>
          <div>医疗发票</div>
          <BoxUpload
            :fileList="filesObj.YLFP"
            attachmentType="YLFP"
            :count="0"
            :disabled="!(editObj.YLFP.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>诊断证明</div>
          <BoxUpload
            :fileList="filesObj.ZDZM"
            attachmentType="ZDZM"
            :count="0"
            :disabled="!(editObj.ZDZM.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>伤情照片</div>
          <BoxUpload
            :fileList="filesObj.SQZP"
            attachmentType="SQZP"
            :count="0"
            :disabled="!(editObj.SQZP.edit && canEdit)"
          ></BoxUpload>
        </div>
        <div>
          <div>医疗影片</div>
          <BoxUpload
            :fileList="filesObj.YLYP"
            attachmentType="YLYP"
            :count="0"
            :disabled="!(editObj.YLYP.edit && canEdit)"
          ></BoxUpload>
        </div>
      </AssociationScrollView>
    </AssociationScroll>
    <div class="action-footer">
      <el-button @click="back" class="base-btn white-btn">返回</el-button>
      <el-button
        v-if="!canEdit"
        @click="getControlsFileEdit(true)"
        class="base-btn"
        icon="el-icon-document"
        style="background-color: #4278c9"
        >编辑附件</el-button
      >
      <el-button
        v-if="canEdit"
        @click="submit"
        class="base-btn"
        icon="el-icon-circle-check"
        style="background-color: #4278c9"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import BoxUpload from "@/components/DragMultipleUpload/BoxUpload";
import {
  getNewEnergyClaimAttachments,
  uploadNewEnergyClaimAttachments,
} from "@/api/newEnergyclaim";
export default {
  name: "newEnergyClaimAttachmentDetails",
  components: {
    AssociationScroll,
    AssociationScrollView,
    BoxUpload,
  },
  data() {
    return {
      caseId: "",
      latestStatus: 0,
      canEdit: false,
      filesObj: {
        PFYXJQYZRS: [],
        SPSQS: [],
        QTBCCLSS: [],
        YHK: [],
        SFZTXM: [],
        SFZGHM: [],
        JSZ: [],
        XSZZY: [],
        XSZFY: [],
        XSNSY: [],
        SZJSZ: [],
        SZXSZZY: [],
        SZXSZFY: [],
        SZXSZNSY: [],
        YLFP: [],
        ZDZM: [],
        SQZP: [],
        YLYP: [],
        YYZZZY: [],
        YYZZFY: [],
        SGQMZP: [],
        SSWZXXQK: [],
        JTSGRDS: [],
        SFQKZM: [],
        QTBCQK: [],
        CSRYSFSS: [],
        SFSJSZRS: [],
      },
      editObj: {
        PFYXJQYZRS: {
          edit: false,
          key: 8,
        },
        SPSQS: {
          edit: false,
          key: 8,
        },
        QTBCCLSS: {
          edit: false,
          key: 8,
        },
        YHK: {
          edit: false,
          key: 8,
        },
        SFZTXM: {
          edit: false,
          key: 8,
        },
        SFZGHM: {
          edit: false,
          key: 8,
        },
        JSZ: {
          edit: false,
          key: 8,
        },
        XSZZY: {
          edit: false,
          key: 8,
        },
        XSZFY: {
          edit: false,
          key: 8,
        },
        XSNSY: {
          edit: false,
          key: 8,
        },
        SZJSZ: {
          edit: false,
          key: 8,
        },
        SZXSZZY: {
          edit: false,
          key: 8,
        },
        SZXSZFY: {
          edit: false,
          key: 8,
        },
        SZXSZNSY: {
          edit: false,
          key: 8,
        },
        YLFP: {
          edit: false,
          key: 8,
        },
        ZDZM: {
          edit: false,
          key: 8,
        },
        SQZP: {
          edit: false,
          key: 8,
        },
        YLYP: {
          edit: false,
          key: 8,
        },
        YYZZZY: {
          edit: false,
          key: 8,
        },
        YYZZFY: {
          edit: false,
          key: 8,
        },
        SGQMZP: {
          edit: false,
          key: 5,
        },
        SSWZXXQK: {
          edit: false,
          key: 5,
        },
        JTSGRDS: {
          edit: false,
          key: 5,
        },
        SFQKZM: {
          edit: false,
          key: 5,
        },
        QTBCQK: {
          edit: false,
          key: 5,
        },
        CSRYSFSS: {
          edit: false,
          key: 5,
        },
        SFSJSZRS: {
          edit: false,
          key: 5,
        },
      },
      dataForm: {},
    };
  },
  created() {
    this.caseId = this.$route.params.id;
    this.latestStatus = this.$route.params.latestStatus || 0;
    this.getControlsFileEdit();
    getNewEnergyClaimAttachments({ caseId: this.caseId }).then((res) => {
      this.handleDetails(res.data);
    });
  },
  methods: {
    // 详情数据处理
    handleDetails(data = {}) {
      for (const key in this.filesObj) {
        data.forEach((item) => {
          if (item.attachmentType == key) {
            this.filesObj[key].push(item);
          }
        });
      }
      // console.log(this.filesObj);
    },
    // 处理上传数据
    handleUpload() {
      let req = [];
      for (const key in this.filesObj) {
        if (this.filesObj[key].length > 0) {
          req = [...req, ...this.filesObj[key]];
        }
      }
      return req;
    },
    back() {
      this.$router.go(-1);
    },
    getControlsFileEdit(isCan = false) {
      this.canEdit = isCan;
      for (const k in this.editObj) {
        this.editObj[k].edit =
          this.canEdit && this.editObj[k].key >= this.latestStatus;
      }
    },
    submit() {
      uploadNewEnergyClaimAttachments({
        uploadFileList: this.handleUpload(),
        caseId: this.caseId,
      }).then((res) => {
        this.back();
        this.$message({
          showClose: true,
          message: "保存成功！",
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  position: relative;
}
.action-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
}
.base-btn {
  height: 32px;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border: none;
  border-radius: 4px 4px 4px 4px;
}
.white-btn {
  border: 1px solid #cccccc;
  color: #333333;
}
.green-btn {
  background: #00bc0d;
}
</style>
